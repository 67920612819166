
import Vue from "vue";
import { mapGetters } from "vuex";
import LegalInfo from "~/components/layout/footer/footer-bottom/LegalInfo.vue";

export default Vue.extend({
  name: "SiteFooterBottom",
  components: {
    LegalInfo,
  },
  data() {
    return {
      getSiteFooterBottomItems: [
        {
          title: this.$tc("footer.privacy"),
          link: "/privacy-policy",
          external: false,
        },
        {
          title: this.$tc("footer.cookies"),
          link: "/cookie-policy",
          external: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("countrySetting", ["showFooterLegalInfo"]),
  },
});
