
import Vue from "vue";
import { mapGetters } from "vuex";
import LazyHydrate from "vue-lazy-hydration";
import FooterBottom from "~/components/layout/footer/footer-bottom/FooterBottom.vue";
import { footerConfig } from "~/services/config.service";

export default Vue.extend({
  components: {
    LazyHydrate,
    FooterBottom,
    FooterMiddle: () =>
      import(
        /* webpackChunkName: "FooterMiddle" */ "~/components/layout/footer/footer-middle/FooterMiddle.vue"
      ),
    PaymentOptions: () =>
      import(
        /* webpackChunkName: "PaymentOptions" */ "~/components/layout/footer/payment-options/PaymentOptions.vue"
      ),
    FooterPaypalCredit: () =>
      import(
        /* webpackChunkName: "FooterPaypalCredit" */ "~/components/layout/footer/finance-terms/FooterPaypalCredit.vue"
      ),
    MobileAppBanner: () =>
      import(
        /* webpackChunkName: "MobileAppBanner" */ "~/components/layout/footer/mobile-app-banner/MobileAppBanner.vue"
      ),
  },
  props: {
    reduced: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      paymentOptions: [],
      socialLinks: {},
    };
  },

  computed: {
    ...mapGetters("countrySetting", ["showFooterPaypalCreditInfo"]),
  },

  async created() {
    const footer = await footerConfig(this.$config.localeInstance);
    this.paymentOptions = footer?.paymentOptionIds ?? [];
    this.socialLinks = footer?.socialLinks ?? {};
  },
});
